import { required } from 'vuelidate/lib/validators'

import address from './Address'
import phones from './Phones'
import emails from './Emails'

export default {
  contacts: {
    $each: {
      contact: {
        firstName: {
          required
        },
        lastName: {
          required
        },
        ...address,
        ...phones,
        ...emails
      }
    }
  }
}
