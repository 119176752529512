<template>
  <address-view 
    :addresses="addresses"
    :v="$entity.addresses"
    :is-tiled="true" />
</template>

<script>
import { AddressView } from '@/components/Address'

export default {
  name: 'VendorAddress',
  inject: ['$vv'],
  components: {
    AddressView
  },
  props: {
    entity: null,
    v: null // Optional Validator Object
  },
  computed: {
    addresses: function() {
      return this.entity.addresses
    },
    $entity: function() {
      if (this.$v.entity) {
        // From route view
        return this.$v.entity
      } else {
        // From component tab
        return this.$v
      }
    },
    $v() {
      return this.$vv
    }
  },
  mounted() {
    this.$v.entity.addresses.$touch()
  },
  beforeRouteLeave: function(to, from, next) {
    // const message = 'Validation errors. Please fix before proceeding'
    // const title = 'Vendor'
    // if (!this.$v.entity.addresses.$error || this.entity.isNew) {
    next()
    // } else {
    //   this.$router.replace(from.path)
    //   this.$notification.error(title, message)
    // }
  }
}
</script>
