import { required, between, numeric, minLength, maxLength, helpers } from 'vuelidate/lib/validators'
import addresses from '@/assets/validateForms/Address'
import phones from '@/assets/validateForms/Phones'
import emails from '@/assets/validateForms/Emails'
import contacts from '@/assets/validateForms/Contacts'
import { abnValidator, abnMinLength } from '@/components/validators'
import store from '@/store'

const countryCode = () => {
  const company = store.getters['company/company']
  return company.info.countryCode
}

const shouldValidateAbn = () => {
  if (countryCode() === 'AU') {
    return true
  } else {
    return false
  }
}

// eslint-disable-next-line no-unused-vars
// abnMinLength = () => {
//   const company = store.getters['company/company']
//   if (company.info.countryCode === 'AU') {
//     return 11
//   } else {
//     return 0
//   }
// }

// eslint-disable-next-line no-unused-vars
const validateIf = (prop, validator) =>
  helpers.withParams({ type: 'validatedIf', prop }, function(value, parentVm) {
    if (prop) {
      return validator(value)
    } else {
      return true
    }
    // return helpers.ref(prop, this, parentVm) ? validator(value) : true
  })

// const validateAbnLength = helpers.withParams({ type: 'validateAbnLength' }, function (value) {
//   const company = getStoreCompany()
//   if (company.info.countryCode === 'MY') {
//     return true
//   } else {
//     return helpers.len(value) === 11
//   }
// })

export default {
  provide: function () {
    return {
      $vv: this.$v
    }
  },
  validations: {
    detailGroup: ['entity.name', 'entity.vendorAccountNo', 'entity.abn', 'entity.bsb', 'entity.accountNo', 'entity.settlementDiscount', 'entity.glAccount'],
    emailsPhonesGroup: ['entity.emails', 'entity.phones'],
    addressesGroup: ['entity.addresses'],
    contactsGroup: ['entity.contacts'],
    entity: {
      name: {
        required,
        maxLength: maxLength(120)
      },
      vendorAccountNo: {
        //numeric
      },
      abn: {
        minLength: minLength(abnMinLength(countryCode())), // minLength(shouldValidateAbnLength() ? 0 : 11),
        validAbn: (value) => {
          if (shouldValidateAbn()) {
            return abnValidator(value)
          } else {
            return true
          }
        }
      },
      bsb: {
        minLength: minLength(6)
      },
      accountNo: {
        numeric
      },
      settlementDiscount: {
        required,
        between: between(0, 100)
      },
      glAccount: {
        required
      },
      ...addresses,
      ...contacts,
      ...phones,
      ...emails
    }
  }
}
