import { mapGetters, mapActions } from 'vuex'

const moduleName = 'vendors'

export default {
  computed: {
    // storeItem() {
    //   return {
    //     id: this.entity ? this.entity.id : '',
    //     entity: this.entity
    //   }
    // },
    ...mapGetters(moduleName, {
      returnRoute: 'returnRoute',
      snapshots: 'snapshots',
      baseSnapshot: 'baseSnapshot',
      currentSnapshot: 'currentSnapshot',
      snapshotDiff: 'snapshotDiff'
    }),
    ...mapGetters(['licenceExpired'])
  },
  methods: {
    ...mapActions(moduleName, ['getStoreItem', 'addStoreItem', 'saveSnapshot', 'clearSnapshots', 'setReturnRoute']),
    ...mapActions('sundrycreditors', { setSundryCreditorVendor: 'setVendor' }),
    ...mapActions('purchaseorders', { setPurchaseOrderVendor: 'setVendor' }),
    ...mapActions('printpreview', { setPrintPreviewReturnRoute: 'setReturnRoute', addEmailer: 'addEmailer', removeEmailer: 'removeEmailer' }),
    ...mapActions('printpreview', { addReportParameters: 'addParameters', removeReportParameters: 'removeParameters' })
  }
}
