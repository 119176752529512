<template>
  <div>
    creating...
  </div>
</template>
<script>
import StoreMixin from './storeMixin'

export default {
  mixins: [StoreMixin],
  async created() {
    this.addStoreItem()
  }
}
</script>
