<template>
  <div v-if="entity">
    <app-detail-header
      :show-toggle="true"
      :show-new="false"
      :show-delete="entity.isActive && !entity.isNew"
      :show-print="!entity.isNew"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <app-side-menu
          :min-width="18"
          :fixed-menu="false">
          <aside
            class="menu"
            slot="side-menu">
            <!-- Consider applying this (max-width) to all side menu titles -->
            <p
              class="menu-label"
              style="max-width: 200px;">
              <span class="title is-6">
                <strong>
                  {{ entity.name }}
                </strong>
              </span>
            </p>
            <ul class="menu-list">
              <li>
                <router-link
                  :to="{ name: routeTypes.VendorDetail.name, query: $route.query }"
                  :class="{ 'is-active': $route.name === routeTypes.VendorDetail.name }">
                  <span
                    :class="{ 'has-badge-danger has-badge-small has-badge-rounded has-badge-offset2': $v.detailGroup.$error }"
                    :data-badge="$v.detailGroup.$error ? '' : null">
                    Detail Info
                  </span>
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: routeTypes.VendorAddress.name, query: $route.query }"
                  active-class="is-active">
                  <span
                    :class="{ 'has-badge-danger has-badge-small has-badge-rounded has-badge-offset2': $v.addressesGroup.$error }"
                    :data-badge="$v.addressesGroup.$error ? '' : null">
                    Addresses
                  </span>
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: routeTypes.VendorPhoneEmail.name, query: $route.query }"
                  active-class="is-active">
                  <span
                    :class="{ 'has-badge-danger has-badge-small has-badge-rounded has-badge-offset2': $v.emailsPhonesGroup.$error }"
                    :data-badge="$v.emailsPhonesGroup.$error ? '' : null">
                    Phones/Emails
                  </span>
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: routeTypes.VendorContacts.name, query: $route.query }"
                  active-class="is-active">
                  <span
                    :class="{ 'has-badge-danger has-badge-small has-badge-rounded has-badge-offset2': $v.contactsGroup.$error }"
                    :data-badge="$v.contactsGroup.$error ? '' : null">
                    Contacts
                  </span>
                </router-link>
              </li>
            </ul>
          </aside>
        </app-side-menu>
      </div>
      <div class="column">
        <router-view
          :entity="entity"
          @toggleActive="onToggleActive" />
        <!-- <pre>
            {{ $v }}
          </pre> -->
      </div>
    </div>
    <unsaved-modal
      :active.sync="isUnsavedModalActive"
      @close="closeModal()"
      @skipSave="skipSave()"
      @saveContinue="saveContinue()">
      <p slot="text-title">Unsaved Changes</p>
      <p slot="text-content">There are unsaved changes. Please select action below</p>
    </unsaved-modal>
    <save-conflict-modal
      :active.sync="isSaveConflictModalActive"
      @close="closeModal()"
      @reload="reloadData()">
      <p slot="text-title">Change conflict</p>
      <p slot="text-content">The data on the server is newer than the local copy. Please reload local data.</p>
    </save-conflict-modal>
    <confirm-modal
      :active.sync="isAbnConfirmModalActive"
      @ok="abnCheck(true)"
      @cancel="abnCheck(false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">ABN</p>
      <p slot="text-content">If you do not enter ABN you will be required to withhold {{ this.$userInfo.withHoldingTax }}% of this supplier's invoices. Enter ABN now?</p>
    </confirm-modal>
    <confirm-modal
      :active.sync="isConfirmModalActive"
      @ok="deleteEntity(true, true)"
      @cancel="deleteEntity(false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Vendor is in use</p>
      <p slot="text-content">
        <span class="has-text-primary has-text-weight-bold">{{ entity.name }}</span> is in use. Set to
        <span class="tag is-danger">In-Active</span> anyway?
      </p>
    </confirm-modal>
    <confirm-modal
      :active.sync="isToggleModalActive"
      @ok="deleteEntity(true, false)"
      @cancel="deleteEntity(false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Vendor is in use</p>
      <p slot="text-content">
        <span class="has-text-primary has-text-weight-bold">{{ entity.name }}</span> is in use. Set to
        <span class="tag is-danger">In-Active</span> anyway?
      </p>
    </confirm-modal>
  </div>
</template>

<script>
import VendorService from './VendorService'
import AppDetailHeader from '@/components/AppDetailHeader'
import AppSideMenu from '@/components/AppSideMenu'
import VendorFormValidation from './VendorFormValidation'
import VendorRoutes from './route-types'
import SundryCreditorRoutes from '@/views/sundrycreditor/route-types'
import PurchaseOrderRoutes from '@/views/purchaseorder/route-types'
import { UnsavedModal, SaveConflictModal, ConfirmModal } from '@/components/BulmaModal'
import HttpStatus from '@/components/http-status'
import { AppHeaderButtonTypes, EventHubTypes } from '@/enums'
import EventBus from '@/components/EventBus'
import StoreMixin from './storeMixin'
import _cloneDeep from 'lodash/cloneDeep'
import _debounce from 'lodash.debounce'
import _isEmpty from 'lodash/isEmpty'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { KeyValuePairModel } from '@/classes/viewmodels'
import { Emailer } from '@/classes'

export default {
  name: 'VendorView',
  components: {
    AppDetailHeader,
    AppSideMenu,
    UnsavedModal,
    SaveConflictModal,
    ConfirmModal
  },
  mixins: [VendorFormValidation, StoreMixin],
  props: {
    isNew: Boolean
  },
  data() {
    return {
      entity: null,
      isUnsavedModalActive: false,
      isSaveConflictModalActive: false,
      isAbnConfirmModalActive: false,
      isConfirmModalActive: false,
      isToggleModalActive: false,
      isSkipSave: false,
      isSaveContinue: false,
      toRoute: null
    }
  },
  computed: {
    validateError() {
      return this.$v.$error || this.$v.detailGroup.$error || this.$v.emailsPhonesGroup.$error || this.$v.addressesGroup.$error || this.$v.contactsGroup.$error
    },
    routeTypes() {
      return VendorRoutes
    }
  },
  watch: {
    entity: {
      handler: _debounce(function(newVal) {
        if (newVal) {
          this.$forceUpdate()
          this.saveSnapshot(_cloneDeep(this.entity))
        }
      }, 500),
      deep: true
    }
  },
  async created() {
    this.getEntity()
  },
  mounted() {},
  beforeDestroy() {
    // this.clearSnapshots(this.entity.id)
  },
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          break
        case AppHeaderButtonTypes.Print:
          this.print()
          break
        case AppHeaderButtonTypes.Delete:
          this.checkBeforeDelete()
          break
        case AppHeaderButtonTypes.Save:
          this.save()
          break
        case AppHeaderButtonTypes.Cancel:
          this.cancel()
          break
      }
    },
    async getEntity(reset = false) {
      try {
        this.$showSpinner()
        if (reset) {
          this.clearSnapshots(this.$route.params.vendorId)
        }
        if (!this.currentSnapshot) {
          await this.getStoreItem(this.$route.params.vendorId)
        }
        this.entity = _cloneDeep(this.currentSnapshot)
      } catch (e) {
        this.$notification.openMessageXhrError('', e)
      }
      this.$hideSpinner()
    },
    async save(isDelete) {
      const title = 'Vendor'
      if (this.validateError) {
        this.$notification.validationError(title)
        return false
      }

      this.$showSpinner('Vaidating...')
      if (this.entity.isActive && !this.entity.abn === false) {
        // this.abnInUse = await VendorService.assertAbnUsed(this.entity.id, this.entity.abn)

        // if (this.abnInUse) {
        //   this.$hideSpinner()
        //   this.$notification.openNotificationWithType('warning', 'Saving not allowed', 'ABN already exists')
        //   return false
        // }
      }
      this.$hideSpinner()

      try {
        this.$showSpinner('Saving...')
        this.saveSnapshot(_cloneDeep(this.entity))

        if (this.entity.isNew) {
          await VendorService.postEntity(this.entity)
        } else if (this.snapshotDiff) {
          await VendorService.putEntity(_cloneDeep(this.entity), this.snapshotDiff)
        } else {
          this.$notification.openNotificationWithType('warning', title, 'No changes detected. Not saved')
          return false
        }

        // if (this.isSaveContinue) {
        //   this.$notification.openNotificationWithType('success', title, isDelete ? `${title} set as In-Active` : 'Save successful')
        // } else if (response && response.status === HttpStatus.NO_CONTENT) {
        //   await this.getEntity(true)
        //   this.$notification.openNotificationWithType('success', title, isDelete ? `${title} set as In-Active` : 'Save successful')
        // } else if (response && response.status === HttpStatus.CREATED) {
        //   await this.getEntity(true)
        //   this.$notification.openNotificationWithType('success', title, `New  ${title}  added`)
        // }
        this.$notification.success(title, isDelete ? `${title} set to In-Active` : 'Save successful')
        if (!this.isSaveContinue) {
          await this.getEntity(true)
        }
        this.$eventHub.$emit(EventHubTypes.EntitySaved)
        return true
      } catch (e) {
        EventBus.$emit('serviceError', e.response.status)
        if (e.response.request.status === HttpStatus.CONFLICT) {
          this.isSaveConflictModalActive = true
        } else {
          this.$notification.openMessageXhrError(title, e)
        }
        return false
      } finally {
        this.$hideSpinner()
      }
    },
    deleteEntity(confirmDelete, save) {
      this.isConfirmModalActive = false
      this.isToggleModalActive = false
      if (confirmDelete) {
        this.entity.isActive = false
        // save snapshots immediately not waiting for debounce
        if (save) {
          this.save(true)
        }
      } else {
        this.entity.isActive = true
      }
    },
    cancel() {
      this.saveSnapshot(_cloneDeep(this.entity))
      if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue) {
        console.log(this.snapshotDiff)
        this.isUnsavedModalActive = true
      } else {
        if (this.toRoute) {
          this.$router.push(this.toRoute.fullPath)
        } else if (this.returnRoute && !_isEmpty(this.returnRoute)) {
          this.$router.push(this.returnRoute.fullPath)
        } else {
          this.$router.push({
            name: this.routeTypes.VendorListView.name
          })
        }
      }
    },
    print() {
      let params = Object.assign(
        {
          CompanyID: this.$userInfo.companyId,
          VendorID: this.entity.id
        }
      )
      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)

      const emailer = new Emailer()
      emailer.assetId = this.entity.id
      emailer.subject = 'Vendor Detail Report'
      emailer.reportName = 'rptVendorDetails'
      this.addEmailer(emailer)
      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: this.$route.meta.report },
        query: { parameterId: this.$guid.newGuid(), emailerId: emailer.id }
      })
    },
    async saveContinue() {
      this.isUnsavedModalActive = false
      this.isSaveContinue = true
      this.isSaveContinue = await this.save()
      if (this.isSaveContinue) {
        this.cancel()
      }
    },
    skipSave() {
      this.isUnsavedModalActive = false
      this.isSkipSave = true
      this.cancel()
    },
    redirectToRoute() {
      if (this.toRoute) {
        this.$router.push(this.toRoute.fullPath)
      } else if (!this.entity.isNew) {
        this.cancel()
      }
    },
    closeModal() {
      this.isUnsavedModalActive = false
      this.isSaveConflictModalActive = false
      this.isSaveContinue = false
    },
    async reloadData() {
      this.isSaveConflictModalActive = false
      this.isSaveContinue = false
      this.getEntity(true)
      // this.$eventHub.$emit(EventHubTypes.EntityReload)
    },
    async checkBeforeDelete() {
      this.$showSpinner()
      const isInUse = await VendorService.assertUsed(this.entity.id)
      this.$hideSpinner()
      if (isInUse) {
        this.isConfirmModalActive = true
      } else {
        this.deleteEntity(true, true)
      }
    },
    async onToggleActive() {
      if (this.entity.isActive) {
        // Reserved for future action
      } else {
        this.$showSpinner()
        const isInUse = await VendorService.assertUsed(this.entity.id)
        this.$hideSpinner()
        if (isInUse) {
          this.isToggleModalActive = true
        } else {
          this.deleteEntity(true, false)
        }
      }
    }
  },
  beforeRouteLeave: function(to, from, next) {
    this.saveSnapshot(_cloneDeep(this.entity))
    //console.log(this.snapshotDiff)
    if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue) {
      this.$router.replace(from.path)
      this.toRoute = to
      this.isUnsavedModalActive = true
    } else {
      if (to.name === SundryCreditorRoutes.SundryCreditorDetail.name && (!this.entity.isNew || this.isSaveContinue) && !this.isSkipSave) {
        this.setSundryCreditorVendor({
          id: to.params.sundryCreditorId,
          vendor: {
            key: this.entity.id,
            value: this.entity.name
          },
          abn: this.entity.abn,
          glAccount: this.entity.glAccount
        })
      }
      if (to.name === PurchaseOrderRoutes.PurchaseOrderDetail.name && (!this.entity.isNew || this.isSaveContinue) && !this.isSkipSave) {
        this.setPurchaseOrderVendor({
          id: to.params.purchaseOrderId,
          vendor: {
            key: this.entity.id,
            value: this.entity.name
          }
        })
      }
      this.setReturnRoute({})
      this.clearSnapshots(this.entity.id)
      next()
    }
  }
}
</script>
